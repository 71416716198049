'use strict';

var coreCart = require('core/cart/cart');
var base = require('core/product/base');
var exclusion_class = 'js-refresh-exclude';
var scrollAnimate = require('core/components/scrollAnimate');

/**
 * re-renders the order totals and the number of items in the cart
 * @param {Object} message - Error message to display
 */
coreCart.createErrorNotification = function(message) {
    var errorHtml = '<div class="alert text-center alert-danger alert-dismissible valid-cart-error ' +
        'fade show" role="alert">' +
        '<button type="button" class="close" data-dismiss="alert" aria-label="Close">' +
        '<span aria-hidden="true">&times;</span>' +
        '</button>' + message + '</div>';

    $('.cart-error').append(errorHtml);
}

/**
 * @description Checks for any unfilled learner forms and stops cart submission if so
 * @param {Object} $button - button element '.checkout-btn' 
 */
coreCart.handleCartSubmit = function($button) {
    var errorMsg = $button.attr('data-missing-learner-error');
    if (!!$('.js-learner-form-section').find('form').length) {
        var $card = $('.js-learner-form-section').find('.card').eq(0);
        module.exports.createErrorNotification(errorMsg);
        scrollAnimate($card.parents('.product-info'));
        $card.addClass('active');
    } else {
        window.location.href = $button.attr('href');
    }
}

/**
 * @description Sets and displays saved learner information on the front end
 * @param {Object} $form - Learner Form
 * @param {Object} savedLearnerInformation - Object with saved learner information or empty string
 * @param {Boolean} resetHeader - Flag to reset card title to default title. (Learner #1, #2, #3, etc)
 */
coreCart.fillLearnerForm = function($form, savedLearnerInformation, resetHeader = false) {
    $form.find('input#firstName').val(savedLearnerInformation.firstName);
    $form.find('input#lastName').val(savedLearnerInformation.lastName);
    $form.find('input#email').val(savedLearnerInformation.email);
    $form.find('input#jobTitle').val(savedLearnerInformation.jobTitle);
    $form.find('input#learnerID').val(savedLearnerInformation.id);

    if (resetHeader) {
        var defaultTitle = $form.parents('.card').find('.card-title').attr('data-card-title');
        $form.parents('.card').eq(0).find('.card-title').text(defaultTitle);
    }
}

/**
 * @description Builds wrapper template for learner form and inserts into DOM
 * @param {Object} learnerStore - Object with saved learner information or empty string
 */
coreCart.buildLearnerSelectOuterTemplate = function(learnerStore) {
    $('.learner-form').each(function(index) {
        if (!$(this).parents('.cart-attendee').hasClass(exclusion_class) && !!$(this).parents('.js-learner-form-section').length) {
            var learnerStoreMutation = learnerStore;
            var html = '';
            var learnerIDMap = [];
            var learnerIDInputs = $(this).parents('.cart-attendee').find('input[name="learnerID"]');
            var pid = $(this).parents('.product-info').find('.remove-product').attr('data-pid');
            if (learnerIDInputs.length > 0) {
                $.map(learnerIDInputs, (e) => {
                    if (!!e.value) {
                        learnerIDMap.push({id: e.value});
                    }
                });
                
                for (var i = 0; i< learnerIDMap.length; i++) {
                    learnerStoreMutation = learnerStoreMutation.filter(l => l.id !== learnerIDMap[i].id);
                }
            }

            html = `
                <div class="js-learner-store-selection learner-store-selection">
                ${coreCart.buildLearnerSelectTemplate(learnerStoreMutation, pid, index)}
                <div class="custom-control custom-radio mt-2">
                <input type="radio" class="custom-control-input js-saved-learner-new" id="savedLearnerRefresh_new_${pid}_${index}" name="savedLearnerRefresh_${pid}_${index}" value="button" checked/>
                <label class="custom-control-label" for="savedLearnerRefresh_new_${pid}_${index}">New</label>
                </div>
                </div>
            `;
            
            if ($(this).prev().hasClass('js-learner-store-selection')) {
                $(this).siblings('.learner-store-selection').remove();
            }
            
            $(html).insertBefore($(this));
        }
    });

    $('.cart-attendee').removeClass(exclusion_class);
}

/**
 * @description Maps leaner store object data and builds inner template for learner form 
 * @param {Object} learnerStore - Object with saved learner information or empty string
 * @param {Integer} pid - Associated Product ID
 * @param {Integer} parentIndex - Loop index of parent loop from coreCart.buildLearnerSelectOuterTemplate
 * @returns {String} - Mapped HTML template of learners
 */
coreCart.buildLearnerSelectTemplate = function(learnerStore, pid, parentIndex) {
    var template = `
        ${$.map(learnerStore, (learner, index) => `
            <div class="custom-control custom-radio mt-2">
                <input type="radio" class="custom-control-input" id="savedLearnerRefresh_${learner.id}_${pid}_${index}_${parentIndex}" name="savedLearnerRefresh_${pid}_${parentIndex}" value="button" 
                data-learner-saved-first-name="${learner.firstName}"
                data-learner-saved-last-name="${learner.lastName}"
                data-learner-saved-email="${learner.email}"
                data-learner-saved-job-title="${learner.jobTitle}"
                />
                <label class="custom-control-label" for="savedLearnerRefresh_${learner.id}_${pid}_${index}_${parentIndex}">${learner.firstName} ${learner.lastName}</label>
            </div>
        `).join('')}
    `;

    return template;
};

/**
 * @description Fetches learner store data and calls coreCart.buildLearnerSelectOuterTemplate();
 * @param {String} url - Cart-RefreshLearnerForm
 */
coreCart.getRefreshedLearnerSelectors = function(url) {
    var learnerStore = '';
    $.ajax({
        url: url,
        type: 'get',
    }).done(function(data) {
        learnerStore = data.learnerStore;
        coreCart.buildLearnerSelectOuterTemplate(learnerStore);
    });
};

/**
 * @description Sets and displays saved learner information on the front end
 * @param {Object} data - Data returned form Cart-SaveLearner
 */
coreCart.setSavedLearners = function(data) {
    $('.js-learner-row-current').remove();
    $('.js-learner-saved-row-current').empty().append(data.template);
    $('.js-learner-saved-row-current').removeClass('.js-learner-saved-row-current')
}

/**
 * @description Handles Saved Learner form submission
 * @param {Object} form - The save learner form
 */
coreCart.handleLearnerSubmit = function (form) {
    var url = form.attr('action');
    var refreshedLearnerFormUrl = form.attr('data-get-new-learner-forms');
    var pid = form.parents('.product-info').find('.remove-product').attr('data-pid');
    var data = {
        pid: pid,
        firstName: form.find('input#firstName').val(),
        lastName: form.find('input#lastName').val(),
        email: form.find('input#email').val(),
        jobTitle: form.find('input#jobTitle').val(),
        learnerIndex: form.find('input#learnerIndex').val(),
        learnerID: form.find('input#learnerID').val()
    }
    
    $.ajax({
        url: url,
        type: 'post',
        data: data,
    }).done(function(data) {
        form.parents('.js-learner-row').addClass('js-learner-row-current');
        form.parents('.cart-attendee').find('.js-learner-saved-row').addClass('js-learner-saved-row-current');
        coreCart.setSavedLearners(data);
        coreCart.getRefreshedLearnerSelectors(refreshedLearnerFormUrl);
    });
};

/**
 * @description Handles Saved Learner form submission
 * @param {Object} form - The save learner form
 */
coreCart.handleSavedLearnerSelect = function ($select) {
    var $form = $select.parents('.js-learner-store-selection').next('form');
    var savedLearnerInformation = {
        firstName: $select.hasClass('js-saved-learner-new') ? '' : $select.attr('data-learner-saved-first-name'),
        lastName: $select.hasClass('js-saved-learner-new') ? '' : $select.attr('data-learner-saved-last-name'),
        email: $select.hasClass('js-saved-learner-new') ? '' : $select.attr('data-learner-saved-email'),
        jobTitle: $select.hasClass('js-saved-learner-new') ? '' : $select.attr('data-learner-saved-job-title'),
        id: $select.hasClass('js-saved-learner-new') ? '' : $select.attr('data-learner-id')
    };

    if ($select.hasClass('js-saved-learner-new')) {
        $form.find('.form-group').removeClass('d-none');
    } else {
        $form.find('.form-group').addClass('d-none');
    }

    coreCart.fillLearnerForm($form, savedLearnerInformation);
};

/**
 * @description Handles Remove learner submission
 * @param {Object} form - The save learner form
 */
coreCart.handleLearnerRemove = function ($this) {
    var url = $this.attr('data-action');
    var pid = $this.parents('.product-info').find('.remove-product').attr('data-pid');
    var savedLearnerInformation;
    var data = {
        pid: pid,
        learnerID: $this.attr('data-learner-id')
    }
    
    $.ajax({
        url: url,
        type: 'post',
        data: data,
    }).done(function(data) {
        if (data.learnerRemoved) {
            var $form = $this.parents('.learner-form');
            savedLearnerInformation = {
                firstName: '',
                lastName: '',
                email: '',          
                jobTitle: ''
            };
            
            coreCart.fillLearnerForm($form, savedLearnerInformation, true);
            
        }
    });
};

 /**
   * Updates details of a product line item
   * @param {Object} data - AJAX response from the server
   */
coreCart.updateLearnerForm = function(data, uuid) {
    var $productInfo = $('.cart-page').find('.product-info.uuid-' + uuid);
    if (data.quantityAboveLearners) {
        if(!!$productInfo.find('.js-learner-form-section').length) {
            $productInfo.find('.js-learner-form-section').replaceWith(data.template);
        } else {
            $productInfo.find('.cart-attendee').append(data.template);
        }
    } else {
        var learnerToRemove = $productInfo.find('.js-learner-row').last();
        var url = learnerToRemove.find('.js-remove-learner-form').attr('data-action');
        var pid = learnerToRemove.find('.js-remove-learner-form').attr('data-pid');
        var learnerID = learnerToRemove.find('.js-remove-learner-form').attr('data-learner-id');

        var data = {
            pid: pid,
            learnerID: learnerID
        }

        $.ajax({
            url: url,
            type: 'post',
            data: data
        }).done(function(data) {
            if (data.learnerRemoved) {
                learnerToRemove.remove();
            }
        });
    }
}

coreCart.handleAutoRenew = function($this) {
    var url = $this.data('action');
    if (!url) {
        return;
    }
    var autorenew = $this.val();
    var productID = $this.data('pid');
    var uuid = $this.data('uuid');
    var urlParams = {
        pid: productID,
        autorenew: autorenew,
        uuid: uuid
    };
    url = module.exports.appendToUrl(url, urlParams);
    $this.parents('.card').spinner().start();
    $('body').trigger('cart:beforeUpdate');
    $.ajax({
        url: url,
        type: 'get',
        context: this,
        dataType: 'json',
        success: function (data) {
            $('.autorenew[data-uuid="' + uuid + '"]').val(autorenew);
            $('body').trigger('cart:update', [data, uuid]);
            $.spinner().stop();
            if ($this.parents('.product-info').hasClass('bonus-product-line-item') && $('.cart-page').length) {
                location.reload();
            }
        },
        error: function (err) {
            if (err.responseJSON.redirectUrl) {
                window.location.href = err.responseJSON.redirectUrl;
            } else {
                module.exports.createErrorNotification(err.responseJSON.errorMessage);
                $.spinner().stop();
            }
        }
    });
}

coreCart.init = function () {
  $('body').on('submit', '.learner-form', function (e){
    e.preventDefault();
    $(this).parents('.cart-attendee').addClass(exclusion_class);
    coreCart.handleLearnerSubmit($(this));
  });

  $('body').on('change', '.js-learner-store-selection input', function() {
      coreCart.handleSavedLearnerSelect($(this));
  });

  $('body').on('click', '.checkout-btn', function(e) {
    e.preventDefault();
    coreCart.handleCartSubmit($(this));
  });

  $('body').on('click', '.remove-product', function (e) {
      e.preventDefault();
      var actionUrl = $(this).data('action');
      var productID = $(this).data('pid');
      var productName = $(this).data('name');
      var uuid = $(this).data('uuid');
      module.exports.confirmDelete(actionUrl, productID, productName, uuid);
  });

  $('body').on('afterRemoveFromCart', function (e, data) {
      e.preventDefault();
      module.exports.confirmDelete(data.actionUrl, data.productID, data.productName, data.uuid);
  });

  $('body').on('click', '.cart-delete-confirmation-btn', function (e) {
      e.preventDefault();
      var productID = $(this).data('pid');
      var url = $(this).data('action');
      var uuid = $(this).data('uuid');
      var urlParams = {
          pid: productID,
          uuid: uuid
      };
      url = module.exports.appendToUrl(url, urlParams);
      $('body > .modal-backdrop').remove();
      $('body').trigger('cart:beforeUpdate');
      $.spinner().start();
      $.ajax({
          url: url,
          type: 'get',
          dataType: 'json',
          success: function (data) {
              if (data.basket.items.length === 0) {
                  $('.cart').empty().append('<div class="row"> ' +
                      '<div class="col-12 text-center"> ' +
                      '<h1>' + data.basket.resources.emptyCartMsg + '</h1> ' +
                      '</div> ' +
                      '</div>'
                  );
                  $('.number-of-items').empty().append(data.basket.resources.numberOfItems);
                  $('.number-of-items-container').removeClass('text-md-right');
                  $('.minicart-quantity').empty().append(data.basket.numItems);
                  $('.minicart-link').attr({
                      'aria-label': data.basket.resources.minicartCountOfItems,
                      title: data.basket.resources.minicartCountOfItems
                  });

                  $('.minicart .popover').empty();
                  $('.minicart .popover').removeClass('show');
                  $('body').removeClass('modal-open');
                  $('html').removeClass('veiled');
              } else {
                  if (data.toBeDeletedUUIDs && data.toBeDeletedUUIDs.length > 0) {
                      for (var i = 0; i < data.toBeDeletedUUIDs.length; i++) {
                          $('.uuid-' + data.toBeDeletedUUIDs[i]).closest('.card').remove();
                      }
                  }
                  $('.uuid-' + uuid).closest('.card').remove();
                  if (!data.basket.hasBonusProduct) {
                      $('.bonus-product').remove();
                  }
                  // NOTE: there's an open issue for problems with removing products with bonus items: https://github.com/SalesforceCommerceCloud/storefront-reference-architecture/issues/748
                  $('.coupons-and-promos').empty().append(data.basket.totals.discountsHtml);
                  module.exports.updateCartTotals(data.basket);
                  module.exports.updateApproachingDiscounts(data.basket.approachingDiscounts);
                  $('body').trigger('setShippingMethodSelection', data.basket);
                  module.exports.validateBasket(data.basket);
              }
              $('body').trigger('cart:update', [data, uuid]);
              $.spinner().stop();
          },
          error: function (err) {
              if (err.responseJSON.redirectUrl) {
                  window.location.href = err.responseJSON.redirectUrl;
              } else {
                  module.exports.createErrorNotification(err.responseJSON.errorMessage);
                  $.spinner().stop();
              }
          }
      });
  });

  $('body').on('quantityStepper:change', (event, stepper) => {
      var $stepper = $(stepper);
      var isMiniCart = $stepper.closest('.minicart').length > 0;
      var selectId = $stepper.closest('.quantity-form').find('select').attr('id');
      var $select = $('.cart-page select#' + selectId);
      var value = parseInt($stepper.find('input').val());
      // if the qty change was triggered from the minicart, manually update cart qty input values
      if (isMiniCart) {
          var $cartStepper = $select.next('.quantity-stepper');
          $cartStepper.find('input').prop('value', value).prop('data-qty', value);
      }
  });

  $('body').on('click', '.js-remove-learner-form', function(e) {
      e.preventDefault();
      var product = $(this).closest('.product-info');
      var quantitySelect = product.find('.quantity');
      var dataAttributeQuantity = quantitySelect.data('pre-select-qty');
      var newQuantity = parseInt(dataAttributeQuantity) - 1;
      coreCart.handleLearnerRemove($(this));
  });

  $('body').on('change', '.quantity-form > .quantity', function() {
      var url = $(this).data('action');
      if (!url) {
          return;
      }
      var preSelectQty = $(this).data('pre-select-qty');
      var quantity = $(this).val();
      var productID = $(this).data('pid');
      var uuid = $(this).data('uuid');
      var urlParams = {
          pid: productID,
          quantity: quantity,
          uuid: uuid
      };
      url = module.exports.appendToUrl(url, urlParams);
      $(this).parents('.card').spinner().start();
      $('body').trigger('cart:beforeUpdate');
      $.ajax({
          url: url,
          type: 'get',
          context: this,
          dataType: 'json',
          success: function (data) {
              $('.quantity[data-uuid="' + uuid + '"]').val(quantity);
              $('.coupons-and-promos').empty().append(data.totals.discountsHtml);
              module.exports.updateCartTotals(data);
              module.exports.updateApproachingDiscounts(data.approachingDiscounts);
              module.exports.updateAvailability(data, uuid);
              module.exports.validateBasket(data);
              coreCart.updateLearnerForm(data, uuid);
              $('body').trigger('cart:update', [data, uuid]);
              $.spinner().stop();
              if ($(this).parents('.product-info').hasClass('bonus-product-line-item') && $('.cart-page').length) {
                  location.reload();
              }
          },
          error: function (err) {
              if (err.responseJSON.redirectUrl) {
                  window.location.href = err.responseJSON.redirectUrl;
              } else {
                  module.exports.createErrorNotification(err.responseJSON.errorMessage);
                  $(this).val(parseInt(preSelectQty, 10));
                  $.spinner().stop();
              }
          }
      });
  });

  $('body').on('change', '.autorenew-form .autorenew', function() {
    coreCart.handleAutoRenew($(this)); 
  });

  $('.shippingMethods').change(function () {
      var url = $(this).attr('data-actionUrl');
      var urlParams = {
          methodID: $(this).find(':selected').attr('data-shipping-id')
      };
      $('.totals').spinner().start();
      $('body').trigger('cart:beforeShippingMethodSelected');
      $.ajax({
          url: url,
          type: 'post',
          dataType: 'json',
          data: urlParams,
          success: function (data) {
              if (data.error) {
                  window.location.href = data.redirectUrl;
              } else {
                  $('.coupons-and-promos').empty().append(data.totals.discountsHtml);
                  module.exports.updateCartTotals(data);
                  module.exports.updateApproachingDiscounts(data.approachingDiscounts);
                  module.exports.validateBasket(data);
              }
              $('body').trigger('cart:shippingMethodSelected', data);
              $.spinner().stop();
          },
          error: function (err) {
              if (err.redirectUrl) {
                  window.location.href = err.redirectUrl;
              } else {
                  module.exports.createErrorNotification(err.responseJSON.errorMessage);
                  $.spinner().stop();
              }
          }
      });
  });

  $('.promo-code-form').submit(function (e) {
      e.preventDefault();
      var currentItemCount = parseInt($('.minicart-quantity').text(), 10) || 0;
      $.spinner().start();
      $('.coupon-missing-error').hide();
      $('.coupon-error-message').empty();
      if (!$('.coupon-code-field').val()) {
          $('.promo-code-form .form-control').addClass('is-invalid');
          $('.promo-code-form .form-control').attr('aria-describedby', 'missingCouponCode');
          $('.coupon-missing-error').show();
          $.spinner().stop();
          return false;
      }
      var $form = $('.promo-code-form');
      $('.promo-code-form .form-control').removeClass('is-invalid');
      $('.coupon-error-message').empty();
      $('body').trigger('promotion:beforeUpdate');
      $.ajax({
          url: $form.attr('action'),
          type: 'GET',
          dataType: 'json',
          data: $form.serialize(),
          success: function (data) {
              if (data.error) {
                  $('.promo-code-form .form-control').addClass('is-invalid');
                  $('.promo-code-form .form-control').attr('aria-describedby', 'invalidCouponCode');
                  $('.coupon-error-message').empty().append(data.errorMessage);
                  $('body').trigger('promotion:error', data);
                  $('.coupon-code-field').trigger('focus');
                  $.spinner().stop();
              } else {
                  // If cart item was added/removed via promo code submit
                  if (['', null, undefined].indexOf(data.numItems) === -1 && currentItemCount !== data.numItems) {
                      // Clean Url Structure of any erroneous parameters
                      if (window.history && ['', null, undefined].indexOf(data.actionUrls.showUrl) === -1) {
                          history.replaceState({}, null, data.actionUrls.showUrl);
                      }
                      // Force uncached reload
                      window.location.reload(true);
                  } else {
                      $('.coupons-and-promos').empty().append(data.totals.discountsHtml);
                      module.exports.updateCartTotals(data);
                      module.exports.updateApproachingDiscounts(data.approachingDiscounts);
                      module.exports.validateBasket(data);
                      $('body').trigger('promotion:success', data);
                      $('.coupon-code-field').val('');
                      $.spinner().stop();
                  }
              }
          },
          error: function (err) {
              $('body').trigger('promotion:error', err);
              if (err.responseJSON.redirectUrl) {
                  window.location.href = err.responseJSON.redirectUrl;
              } else {
                  module.exports.createErrorNotification(err.errorMessage);
                  $.spinner().stop();
              }
          }
      });

      return false;
  });

  $('body').on('click', '.remove-coupon', function (e) {
      e.preventDefault();
      var couponCode = $(this).data('code');
      var uuid = $(this).data('uuid');
      var $deleteConfirmBtn = $('.delete-coupon-confirmation-btn');
      var $productToRemoveSpan = $('.coupon-to-remove');
      $deleteConfirmBtn.data('uuid', uuid);
      $deleteConfirmBtn.data('code', couponCode);
      $productToRemoveSpan.empty().append(couponCode);
  });

  $('body').on('click', '.delete-coupon-confirmation-btn', function (e) {
      e.preventDefault();
      var currentItemCount = parseInt($('.minicart-quantity').text(), 10) || 0;
      var url = $(this).data('action');
      var uuid = $(this).data('uuid');
      var couponCode = $(this).data('code');
      var urlParams = {
          code: couponCode,
          uuid: uuid
      };
      url = module.exports.appendToUrl(url, urlParams);
      $('body > .modal-backdrop').remove();
      $.spinner().start();
      $('body').trigger('promotion:beforeUpdate');
      $.ajax({
          url: url,
          type: 'get',
          dataType: 'json',
          success: function (data) {
              $('.coupon-uuid-' + uuid).remove();
              // If cart item was added/removed via promo code removal
              if (['', null, undefined].indexOf(data.numItems) === -1 && currentItemCount !== data.numItems) {
                  // Clean Url Structure of any erroneous parameters
                  if (window.history && ['', null, undefined].indexOf(data.actionUrls.showUrl) === -1) {
                      history.replaceState({}, null, data.actionUrls.showUrl);
                  }
                  // Force uncached reload
                  window.location.reload(true);
              } else {
                  module.exports.updateCartTotals(data);
                  module.exports.updateApproachingDiscounts(data.approachingDiscounts);
                  module.exports.validateBasket(data);
                  $.spinner().stop();
                  $('body').trigger('promotion:success', data);
              }
          },
          error: function (err) {
              $('body').trigger('promotion:error', err);
              if (err.responseJSON.redirectUrl) {
                  window.location.href = err.responseJSON.redirectUrl;
              } else {
                  module.exports.createErrorNotification(err.responseJSON.errorMessage);
                  $.spinner().stop();
              }
          }
      });
  });

  $('body').on('click', '.cart-page .bonus-product-button', function () {
      $.spinner().start();
      $(this).addClass('launched-modal');
      $.ajax({
          url: $(this).data('url'),
          method: 'GET',
          dataType: 'json',
          success: function (data) {
              $('body').trigger('bonusproduct:edit', data);
              $.spinner().stop();
          },
          error: function () {
              $.spinner().stop();
          }
      });
  });

  $('body').on('hidden.bs.modal', '#chooseBonusProductModal', function () {
      $('#chooseBonusProductModal').remove();
      $('.modal-backdrop').remove();
      $('body').removeClass('modal-open');
      if ($('.cart-page').length) {
          $('.launched-modal .btn-outline-primary').trigger('focus');
          $('.launched-modal').removeClass('launched-modal');
      } else {
          $('.product-detail .add-to-cart').focus();
      }
  });

  $('body').on('change', '.quantity-select', function () {
      var selectedQuantity = $(this).val();
      $('.modal.show .update-cart-url').data('quantity', selectedQuantity);
  });

  $('body').on('click', '.update-cart-product-global', function (e) {
      e.preventDefault();
      var updateProductUrl = $(this).closest('.cart-and-ipay').find('.update-cart-url').val();
      var form = $(this).closest('.cart-and-ipay').find('.update-cart-url').data();
      if (form) {
          form.selectedOptionValueIds = base.methods.getOptions($('#quickViewModal'));
          form.pid = module.exports.getPidValue($(this))
          $(this).parents('.card').spinner().start();
          $('body').trigger('cart:beforeUpdate');
          if (updateProductUrl) {
              $.ajax({
                  url: updateProductUrl,
                  type: 'post',
                  context: this,
                  data: form,
                  dataType: 'json',
                  success: function (data) {
                      $('#quickViewModal').modal('hide');
                      $('.coupons-and-promos').empty().append(data.cartModel.totals.discountsHtml);
                      module.exports.updateCartTotals(data.cartModel);
                      module.exports.updateApproachingDiscounts(data.cartModel.approachingDiscounts);
                      module.exports.updateAvailability(data.cartModel, form.uuid);
                      module.exports.updateProductDetails(data, form.uuid);
                      if (data.uuidToBeDeleted) {
                          $('.uuid-' + data.uuidToBeDeleted).remove();
                      }
                      module.exports.validateBasket(data.cartModel);
                      $('body').trigger('cart:update', [data, form.uuid]);
                      $.spinner().stop();
                  },
                  error: function (err) {
                      if (err.responseJSON.redirectUrl) {
                          window.location.href = err.responseJSON.redirectUrl;
                      } else {
                          module.exports.createErrorNotification(err.responseJSON.errorMessage);
                          $.spinner().stop();
                      }
                  }
              });

          }
      }
  });

  $('body').on('product:afterAddToCartQuickview', () => {
      var verifyCartPage = $('body').find('.page').data('action');
      if (verifyCartPage == 'Cart-Show') {
          location.reload();
      }
  });

  //responsible for filling edit gift cert modal with information from line item on cart page
  $('body').on('click', 'a.edit.editGiftCertificateLineItem', function (e) {
      e.preventDefault();
      var anchor = $(this);
      var uuid = anchor.data('uuid');
      var parentContainer = anchor.parents('.product-info.uuid-' + uuid);
      var from = parentContainer.find('.dwfrm_giftCertificate_purchase_from-' + uuid).data('value');
      var recipient = parentContainer.find('.dwfrm_giftCertificate_purchase_recipient-' + uuid).data('value');
      var recipientEmail = parentContainer.find('.dwfrm_giftCertificate_purchase_recipientEmail-' + uuid).data('value');
      var message = parentContainer.find('.dwfrm_giftCertificate_purchase_message-' + uuid).attr('title');
      var amount = parentContainer.find('.pricing.item-total-' + uuid).data('value');
      var modal = $('#editGiftCertificateLineItemModal');
      modal.find('#from').attr('value', from);
      modal.find('#recipient').attr('value', recipient);
      modal.find('#recipientEmail').attr('value', recipientEmail);
      modal.find('#confirmRecipientEmail').attr('value', recipientEmail);
      modal.find('#message').html(message || '');
      modal.find('#amount').attr('value', (('' + amount) || '0.0').split('.')[0]);
      modal.find('.btn-update-giftcertificate-incart').attr('data-uuid', uuid);
      modal.find('.btn-update-giftcertificate-incart').data('uuid', uuid);
  });
}



module.exports = coreCart;