'use strict';

var coreSearch = require('core/components/search');

var endpoint = $('.suggestions-wrapper').data('url');

var sitePreferencesUtils = require('core/preferences/sitePreferencesUtils');
var preferences = sitePreferencesUtils.getSitePreferences();
var minChars = preferences.headerSearchMinTermLength;

/**
 * Retrieve suggestions
 *
 * @param {Object} scope - Search field DOM element
 */
coreSearch.getSuggestions = function (scope) {
    if ($(scope).val().length >= minChars) {
        if (!$(scope).hasClass('js-grid-search-form-input')) {
            $.spinner().start();
        }
        
        $.ajax({
            context: scope,
            url: endpoint + encodeURIComponent($(scope).val()),
            method: 'GET',
            success: module.exports.processResponse,
            error: function () {
                $.spinner().stop();
            }
        });
    } else {
        module.exports.toggleSuggestionsIcon('search');
        $(scope).siblings('.reset-button').removeClass('d-sm-block');
        module.exports.clearModals();
        module.exports.getSuggestionsWrapper(scope).empty();
    }
}

module.exports = coreSearch;